<template>
 <h3 class="col-span-2 text-base font-semibold leading-6 text-gray-900">
  {{ $t("callHistory") }}
 </h3>
 <div class="mt-2 overflow-hidden bg-white shadow sm:rounded-md">
  <ul role="list" class="divide-y divide-gray-200">
   <li v-for="(callInfo, idx) in tablePaginatedData" :key="callInfo.id">
    <a
     @click="
      callInfo && callInfo.call_queues && callInfo.call_queues.length > 0
       ? setShowDetail(idx)
       : ''
     "
     class="block hover:bg-gray-50 cursor-pointer">
     <div class="px-4 py-4 sm:px-6">
      <div class="grid grid-cols-5 items-center">
       <div
        class="text-sm text-gray-800 font-medium flex truncate space-x-1 gap-2">
        <span class="flex items-center gap-1">
         <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          :class="[
           callInfo.call_way === 'inbound' ? 'text-blue-400' : 'text-blue-700',
           'w-5 h-5',
          ]">
          <path
           :d="
            callInfo.call_way === 'inbound'
             ? 'M3.5 2A1.5 1.5 0 002 3.5V5c0 1.149.15 2.263.43 3.326a13.022 13.022 0 009.244 9.244c1.063.28 2.177.43 3.326.43h1.5a1.5 1.5 0 001.5-1.5v-1.148a1.5 1.5 0 00-1.175-1.465l-3.223-.716a1.5 1.5 0 00-1.767 1.052l-.267.933c-.117.41-.555.643-.95.48a11.542 11.542 0 01-6.254-6.254c-.163-.395.07-.833.48-.95l.933-.267a1.5 1.5 0 001.052-1.767l-.716-3.223A1.5 1.5 0 004.648 2H3.5zM16.72 2.22a.75.75 0 111.06 1.06L14.56 6.5h2.69a.75.75 0 010 1.5h-4.5a.75.75 0 01-.75-.75v-4.5a.75.75 0 011.5 0v2.69l3.22-3.22z'
             : 'M3.5 2A1.5 1.5 0 002 3.5V5c0 1.149.15 2.263.43 3.326a13.022 13.022 0 009.244 9.244c1.063.28 2.177.43 3.326.43h1.5a1.5 1.5 0 001.5-1.5v-1.148a1.5 1.5 0 00-1.175-1.465l-3.223-.716a1.5 1.5 0 00-1.767 1.052l-.267.933c-.117.41-.555.643-.95.48a11.542 11.542 0 01-6.254-6.254c-.163-.395.07-.833.48-.95l.933-.267a1.5 1.5 0 001.052-1.767l-.716-3.223A1.5 1.5 0 004.648 2H3.5zM16.5 4.56l-3.22 3.22a.75.75 0 11-1.06-1.06l3.22-3.22h-2.69a.75.75 0 010-1.5h4.5a.75.75 0 01.75.75v4.5a.75.75 0 01-1.5 0V4.56z'
           " />
         </svg>
         {{ $t("callIdTitle", { callId: callInfo.call_id }) }}
        </span>
        <div class="whitespace-nowrap text-right text-sm text-gray-500">
         <time :datetime="callInfo.call_start">{{ callInfo.call_start }}</time>
        </div>
       </div>
       <div>
        <p
         class="text-sm text-gray-800 font-normal flex truncate space-x-1 gap-1 justify-center">
         <span v-if="callInfo.did_number">
          {{ $t("caller") }}
          <span class="font-medium"> {{ callInfo.did_number }} </span>
         </span>
         <span v-else> - </span>
        </p>
       </div>
       <div>
        <div
         v-if="
          callInfo && callInfo.call_queues && callInfo.call_queues.length > 0
         "
         class="flex-shrink-0 flex justify-center text-sm">
         <!-- <span
          class="bg-gray-400 h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white">
          <font-awesome-icon
           icon="users"
           class="h-4 w-4 text-white"
           aria-hidden="true" />
         </span> -->
         <div class="flex">
          <div
           v-for="(item, index) in callInfo.call_queues"
           :key="item"
           class="flex items-center">
           <div class="flex items-center gap-1">
            <h2 class="font-medium flex gap-1 items-center">{{ item }}</h2>
            <p class="font-normal">
             {{
              pbxMap &&
              pbxMap.call_queues &&
              pbxMap.call_queues[item] &&
              pbxMap.call_queues[item].name
               ? pbxMap.call_queues[item].name
               : ""
             }}
            </p>
           </div>
           <div class="">
            <svg
             v-if="index < callInfo.call_queues.length - 1"
             class="w-3 h-3"
             fill="none"
             stroke="currentColor"
             viewBox="0 0 24 24"
             xmlns="http://www.w3.org/2000/svg">
             <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 5l7 7-7 7"></path>
            </svg>
           </div>
          </div>
         </div>
        </div>
       </div>
       <div>
        <p
         class="text-sm text-gray-800 font-normal flex truncate space-x-1 gap-1 justify-center">
         <span v-if="callInfo.answered">
          {{ $t("redirected to") }}
         </span>
         <span v-else> - </span>
         <span
          v-if="callInfo.redirect_to_dn"
          class="font-medium flex gap-1 items-center">
          {{ callInfo.redirect_to_dn }} -
          <span
           v-if="
            pbxMap &&
            pbxMap.extensions &&
            pbxMap.extensions[callInfo.redirect_to_dn]
           "
           >{{ pbxMap.extensions[callInfo.redirect_to_dn] }}</span
          >
         </span>
        </p>
       </div>

       <div class="ml-2 flex-shrink-0 flex justify-end">
        <span
         :class="[
          callInfo.answered
           ? 'bg-green-100 text-green-700 ring-green-300'
           : 'bg-red-100 text-red-700 ring-red-300',
          'inline-flex items-center gap-x-1.5 rounded-full px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset',
         ]">
         <svg
          xmlns="http://www.w3.org/2000/svg"
          :class="[
           callInfo.answered ? 'text-green-800' : 'text-red-800',
           'h-4 w-4',
          ]"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor">
          <path
           stroke-linecap="round"
           stroke-linejoin="round"
           stroke-width="2"
           :d="callInfo.answered ? 'M5 13l4 4L19 7' : 'M6 18L18 6M6 6l12 12'" />
         </svg>

         {{
          callInfo.answered
           ? $t("chartTable.data.answered")
           : $t("chartTable.data.unanswered")
         }}
        </span>
       </div>
      </div>
      <!-- <div class="mt-2 sm:flex sm:justify-between">
       <div class="sm:flex">
        <div class="flex items-center text-sm text-gray-500">
         <UsersIcon class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
         <ul :class="callInfo.chain.length > 7 ? 'block' : 'flex'">
          <li v-for="(chain, chainIdx) in callInfo.chain" :key="chainIdx" class="flex items-center">
           <div class="flex">
            <span
             class="inline-flex items-center px-1 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800"
            >
             {{
              chain.elementType === "1"
               ? `${callInfo.display_name} (${callInfo.last_extension})`
               : chain.element
             }}
            </span>
            <span v-show="chainIdx < callInfo.chain.length - 1">
             <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              class="w-5 h-5"
             >
              <path
               fill-rule="evenodd"
               d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
               clip-rule="evenodd"
              />
             </svg>
            </span>
           </div>
          </li>
         </ul>
        </div>
        <p
         class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6"
         v-if="callInfo.talking_duration"
        >
         <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          class="h-5 w-5 text-gray-400 flex-shrink-0 mr-1.5"
         >
          <path
           fill-rule="evenodd"
           d="M10 18a8 8 0 100-16 8 8 0 000 16zm.75-13a.75.75 0 00-1.5 0v5c0 .414.336.75.75.75h4a.75.75 0 000-1.5h-3.25V5z"
           clip-rule="evenodd"
          />
         </svg>
         {{ formatSecsToHourMinAndSecs(callInfo.talking_duration) }}
        </p>
       </div>

       <div class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
        <svg
         xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 20 20"
         fill="currentColor"
         class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
        >
         <path
          fill-rule="evenodd"
          d="M5.75 2a.75.75 0 01.75.75V4h7V2.75a.75.75 0 011.5 0V4h.25A2.75 2.75 0 0118 6.75v8.5A2.75 2.75 0 0115.25 18H4.75A2.75 2.75 0 012 15.25v-8.5A2.75 2.75 0 014.75 4H5V2.75A.75.75 0 015.75 2zm-1 5.5c-.69 0-1.25.56-1.25 1.25v6.5c0 .69.56 1.25 1.25 1.25h10.5c.69 0 1.25-.56 1.25-1.25v-6.5c0-.69-.56-1.25-1.25-1.25H4.75z"
          clip-rule="evenodd"
         />
        </svg>

        <time :datetime="callInfo.call_start_time" class="capitalize">
         {{ $d(callInfo.call_start_time, "longText") }}
        </time>
       </div>
      </div> -->
     </div>
    </a>

    <ThirdPartyNumberAnalyserDetailComponent
     v-if="showIdx.includes(idx)"
     :id="callInfo.call_id"
     :queues="callInfo.call_queues" />
   </li>
  </ul>
 </div>
</template>

<script>
import {
 CalendarIcon,
 LocationMarkerIcon,
 UsersIcon,
} from "@heroicons/vue/solid";

import ThirdPartyNumberAnalyserDetailComponent from "./ThirdPartyNumberAnalyserDetailComponent.vue";
import { mapGetters } from "vuex";
export default {
 props: ["tablePaginatedData", "openAllCalls", "closeAllCalls"],
 components: {
  CalendarIcon,
  LocationMarkerIcon,
  UsersIcon,
  ThirdPartyNumberAnalyserDetailComponent,
 },
 data() {
  return {
   showDetail: false,
   showIdx: [],
  };
 },
 methods: {
  formatTime(time) {
   let formatedTime = "";
   let hour = time.getHours();
   let minutes = time.getMinutes();
   formatedTime = hour + ":" + minutes;
   return formatedTime;
  },
  formatDate(date) {
   let split = date.split("-");
   let hour = split[2].slice(2);
   let day = split[2].slice(0, 2);
   let month = split[1];
   let year = split[0];
   let finalDate = day + "/" + month + "/" + year;
   return [finalDate, hour];
  },
  roundNumber(num) {
   return Math.round((parseFloat(num) + Number.EPSILON) * 100) / 100;
  },
  formatChain(chain) {
   let formatedChain = "";
   let splitChain = [];
   splitChain = chain.split(",");
   for (let index = 0; index < splitChain.length; index++) {
    const element = splitChain[index];
    if (index === 0) {
     formatedChain = element;
    } else {
     formatedChain = formatedChain + " -> " + element;
    }
   }
   return formatedChain;
  },
  goToDetail(id) {
   let routeData = this.$router.resolve({
    path: `/analytics/detail/${id}`,
   });
   window.open(routeData.href, "_blank");
  },
  setShowDetail(idx) {
   this.$emit("setCloseAllCallsToFalse");
   // check if the index of the item on the list is different from the one that is active, and if the chart is opened
   // when clicked - if the index is not the one active - it will open the one that was clicked and close the one that was active
   if (!this.showIdx.includes(idx)) {
    this.showIdx.push(idx);
    // this.showDetail = true;
   } else {
    let findIndex = undefined;
    // this.showDetail = !this.showDetail;
    findIndex = this.showIdx.indexOf(idx);
    this.showIdx.splice(findIndex, 1);
   }
   this.$forceUpdate();
  },
 },
 watch: {
  closeAllCalls: function (val) {
   if (val) {
    this.showIdx.length = 0;
   }
  },
  openAllCalls: function (val) {
   if (val) {
    this.showIdx.length = 0;
    for (let index = 0; index < this.tablePaginatedData.length; index++) {
     this.showIdx.push(index);
    }
   }
  },
  tablePaginatedData: function (val) {
   if (this.openAllCalls) {
    this.showIdx.length = 0;
    for (let index = 0; index < this.tablePaginatedData.length; index++) {
     this.showIdx.push(index);
    }
   }
  },
 },
 computed: {
  ...mapGetters(["pbxMap"]),
 },
};
</script>
