<template>
 <div class="flex space-x-2 my-2 mx-6">
  <div v-for="(item, index) in queues" :key="item" class="flex items-center justify-center gap-2">
   <div class="bg-gray-50 p-2 rounded-md shadow-md">
    <h2 class="text-lg font-semibold">{{ item }}</h2>
    <p class="text-gray-600">
     {{
      pbxMap &&
      pbxMap.call_queues &&
      pbxMap.call_queues[item] &&
      pbxMap.call_queues[item].name
       ? pbxMap.call_queues[item].name
       : ""
     }}
    </p>
   </div>
   <div class="">
    <svg
     v-if="index < queues.length - 1"
     class="w-6 h-6"
     fill="none"
     stroke="currentColor"
     viewBox="0 0 24 24"
     xmlns="http://www.w3.org/2000/svg">
     <path
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M9 5l7 7-7 7"></path>
    </svg>
   </div>
  </div>
 </div>
</template>

<script>
import { mapState } from "vuex";

export default {
 name: "CallAnalyserDetail",
 props: ["id", "data", "queues"],
 components: {},

 data() {
  return {
   arrayOfParticipants: [],
   arrayOfExtensions: [],
   participants: {},
   actionId: {
    id0: "actionId.0",
    id5: "actionId.5",
    id6: "actionId.6",
    id9: "actionId.9",
    id10: "actionId.10",
    id13: "actionId.13",
    id15: "actionId.15",
    id101: "actionId.101",
    id102: "actionId.102",
    id103: "actionId.103",
    id104: "actionId.104",
    id400: "actionId.400",
    id408: "actionId.408",
    id409: "actionId.409",
    id418: "actionId.418",
    id425: "actionId.425",
   },
  };
 },
 methods: {},
 computed: {
  ...mapState(["pbxMap"]),
 },
 mounted() {},
 watch: {},
};
</script>
