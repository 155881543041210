<template>
 <div id="chart">
  <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
 </div>
</template>

<script>
export default {
 props: ["data", "categories"],
 data() {
  return {
   series: this.data,
   chartOptions: {
    chart: {
     type: "bar",
     height: 350,
    },
    plotOptions: {
     bar: {
      borderRadius: 4,
      horizontal: true,
     },
    },
    dataLabels: {
     enabled: false,
    },
    xaxis: {
     categories: this.categories,
    },
   },
  };
 },
};
</script>

<style></style>
